var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        width: 800,
        title: _vm.isEdit ? "编辑" : "新建",
        "on-ok": "handleOk",
      },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("span", [
              _vm.isTranslate
                ? _c("a", { on: { click: _vm.cntinueToBuild } }, [
                    _vm._v("继续新建"),
                  ])
                : _vm._e(),
            ]),
            _c(
              "span",
              [
                _c(
                  "a-button",
                  {
                    key: "back",
                    staticStyle: { "user-select": "none" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("\n          取消\n        ")]
                ),
                _c(
                  "a-button",
                  {
                    key: "submit",
                    staticStyle: { "user-select": "none" },
                    attrs: { type: "primary" },
                    on: { click: _vm.handleOk },
                  },
                  [_vm._v("\n          完成\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "49%" } },
            [
              !_vm.isEdit
                ? _c("a-textarea", {
                    attrs: {
                      placeholder: `请输入提示字词汇\n支持从Excel复制粘贴，多条数据使用换行隔开`,
                      rows: 20,
                    },
                    on: { keydown: _vm.textareaChange },
                    model: {
                      value: _vm.hint,
                      callback: function ($$v) {
                        _vm.hint = $$v
                      },
                      expression: "hint",
                    },
                  })
                : _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.hint,
                      callback: function ($$v) {
                        _vm.hint = $$v
                      },
                      expression: "hint",
                    },
                  }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "49%" } },
            [
              !_vm.isEdit
                ? _c("a-textarea", {
                    attrs: {
                      placeholder: `请输入校正字词汇\n支持从Excel复制粘贴，多条数据使用换行隔开`,
                      rows: 20,
                    },
                    on: { keydown: _vm.textareaChange },
                    model: {
                      value: _vm.revise,
                      callback: function ($$v) {
                        _vm.revise = $$v
                      },
                      expression: "revise",
                    },
                  })
                : _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.revise,
                      callback: function ($$v) {
                        _vm.revise = $$v
                      },
                      expression: "revise",
                    },
                  }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }